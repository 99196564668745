import React, { useState } from 'react';
import { Button, Card, Form, FloatingLabel } from 'react-bootstrap';
import useApiService from '../../services/api/api';

const GeneratePDFButton = ({htmlText, onGenerate}) => {
  const api = useApiService();

  const [generating, setGenerating] = useState(false);
  const [description, setDescription] = useState("");
  const [font, setFont] = useState("Arial");

  const handleGenerate = () => {
    setGenerating(true);

    console.log(htmlText);

    const topic = document.getElementById("floatingTopic").value;

    api.createPDF(htmlText, topic, description, font).then(() => {
      setGenerating(false);
      onGenerate();
    });
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleFont = (e) => {
    setFont(e.target.value);
  }
  
  return (
    <Card>
      <Card.Body>
          <Card.Title>PDF Generierung</Card.Title>
          <Card.Text style={{marginTop: '20px'}}>
              <FloatingLabel controlId="floatingTopic" label="Thema" className="mb-3">
                  <Form.Control type="text" placeholder="Thema" />
              </FloatingLabel>
              <Form.Label style={{marginBottom: '3px'}}>Aufgabenbeschreibung</Form.Label>
              <Form.Control 
                  value={description} 
                  as="textarea" 
                  style={{ height: '200px', marginBottom: '20px' }}
                  onChange={handleDescription}  
              />
              <Form.Select onChange={handleFont} aria-label="font-select" style={{ marginBottom: '30px' }}>
                <option>Wähle eine Schriftart</option>
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Schulschrift">Ausgangsschrift mit alternativem r</option>
                <option value="Druckschrift">Druckschrift</option>
              </Form.Select>
              <Button disabled={generating} variant="primary" onClick={handleGenerate}>Generieren</Button>
          </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default GeneratePDFButton;