const useConfig = () => {
    //const API_URL = "http://192.168.200.178/SchoolAI-1.0.0";
    const API_URL = "https://schoolai.app/SchoolAI-1.0.0";
    const regexp = /\$([a-zA-ZäöüÄÖÜß\-_ ]+)\$/g;
    const themes = ["Dinosaurier", "Ritter", "Wald", "Meer", "Sport", "Ernährung", "Weltraum", "Planeten", "Geschichte", "Tiere", "Planeten", "Drachen", "Flugzeuge", "Autos"];

    const em = "P-1TF0563660079683BM3R7OZI";
    const ey = "P-32692628LH8380802M3SDSCY";
    const sy = "P-9X240008BW830492AM3SDSUQ";
    
    return { API_URL, regexp, themes, em, ey, sy };
}

export default useConfig;